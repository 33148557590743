<template>
  <div class="vc-wrapper">
    <Header
      :current-unit="currentUnit"
      :isExportDisabled="isLoading || items.length == 0"
    />
    <div class="vc-attendance__list">
      <div class="loader" v-if="isLoading && searchTerm === ''">
        <b-spinner label="Loading..."></b-spinner>
      </div>

      <div v-else>
        <AccessDenied v-if="error.status === 403" />
        <div v-else>
          <EmptyState
            v-if="items.length == 0 && searchTerm === ''"
            component-type="record"
          />
          <div v-else>
            <div class="vc-attendance__content">
              <b-container fluid class="p-0">
                <b-row class="vc-attendance-list--header">
                  <div class="vc-attendance__search">
                    <b-input-group
                      :class="{
                        'vc-attendance__search-clear': searchTerm !== ''
                      }"
                    >
                      <b-input-group-prepend is-text>
                        <img
                          @click="onSearch()"
                          src="@/assets/images/virtual-classes/search.svg"
                          alt="search"
                          width="18"
                          height="18"
                        />
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="Find a learner"
                        v-model.trim="searchTerm"
                        @keydown.enter="onSearch"
                      />
                      <b-input-group-append
                        v-if="searchTerm !== ''"
                        @click="onSearchClear"
                        is-text
                      >
                        <img
                          src="@/assets/images/virtual-classes/close.svg"
                          alt="close"
                          width="14"
                          height="14"
                        />
                      </b-input-group-append>
                    </b-input-group>
                  </div>
                  <div class="vc-attendance__bulk-action">
                    <b-dropdown
                      id="dropdown-header"
                      class="vc-attendance__dropdown"
                      no-caret
                      :disabled="!selectedIds.length > 0"
                    >
                      <template #button-content>
                        <p class="mb-0 text-uppercase">
                          Bulk Action
                          <img
                            src="@/assets/images/virtual-classes/chevron-down-blue.svg"
                            alt="Down"
                            class="ml-2"
                          />
                        </p>
                      </template>
                      <b-dropdown-header id="dropdown-header-label">
                        Mark attendace as:
                      </b-dropdown-header>
                      <b-dropdown-item-button
                        @click="markUserAttendance(null, true, 'bulk')"
                        aria-describedby="dropdown-header-label"
                      >
                        Present
                      </b-dropdown-item-button>
                      <b-dropdown-item-button
                        @click="markUserAttendance(null, false, 'bulk')"
                        aria-describedby="dropdown-header-label"
                      >
                        Absent
                      </b-dropdown-item-button>
                    </b-dropdown>
                  </div>
                </b-row>
              </b-container>
              <p class="vc-attendance__search-results" v-if="isSearchEnabled">
                {{
                  items.length > 0
                    ? `${items.length} results found`
                    : `0 results found`
                }}
              </p>
              <div v-if="items.length > 0">
                <b-table
                  small
                  borderless
                  responsive
                  hover
                  :busy.sync="isBusy"
                  :items="items"
                  :fields="fields"
                  :per-page="perPage"
                  thead-tr-class="vcm-list__table--tr"
                  class="vcm-list__table"
                  @row-hovered="onUsersHovered"
                  @row-unhovered="onUsersUnhovered"
                >
                  <template #head(show_details)>
                    <b-form-checkbox
                      v-model="allSelected"
                      :indeterminate="indeterminate"
                      class="vc-checkbox"
                      @change="toggleAll"
                    >
                    </b-form-checkbox>
                  </template>
                  <template #cell(show_details)="data">
                    <b-form-checkbox
                      :id="data.item.name"
                      v-model="selectedIds"
                      :value="data.item.id"
                      class="vc-checkbox"
                    >
                    </b-form-checkbox>
                  </template>
                  <template #cell(first_name)="data">
                    <p>{{ data.item.first_name }} {{ data.item.last_name }}</p>
                  </template>
                  <template #cell(email)="data">
                    <p class="learner-text">{{ data.item.email }}</p>
                  </template>
                  <template #cell(attendance_status)="data">
                    <p class="text-capitalize">
                      {{
                        data.item.attendance_status
                          ? data.item.attendance_status.toLowerCase()
                          : "-"
                      }}
                    </p>
                  </template>
                  <template #cell(attendance_last_updated_at)="data">
                    <div class="vcm-list__table--enrollment">
                      <p class="learner-text">
                        {{
                          data.item.attendance_last_updated_at
                            ? formatDateByPattern(
                                data.item.attendance_last_updated_at,
                                "hh:mm a | DD/MM/YYYY"
                              )
                            : "-"
                        }}
                      </p>
                      <b-dropdown
                        id="dropdown-header"
                        class="vc-attendance__dropdown"
                        variant="link"
                        toggle-class="text-decoration-none"
                        no-caret
                        :class="{
                          visible: data.item.isRowHovered,
                          invisible: !data.item.isRowHovered
                        }"
                      >
                        <template #button-content>
                          <img src="@/assets/three-dots.svg" alt="options" />
                        </template>
                        <b-dropdown-header id="dropdown-header-label">
                          Mark attendace as:
                        </b-dropdown-header>
                        <b-dropdown-item-button
                          @click="markUserAttendance(data.item, true, 'single')"
                          aria-describedby="dropdown-header-label"
                        >
                          Present
                        </b-dropdown-item-button>
                        <b-dropdown-item-button
                          @click="
                            markUserAttendance(data.item, false, 'single')
                          "
                          aria-describedby="dropdown-header-label"
                        >
                          Absent
                        </b-dropdown-item-button>
                      </b-dropdown>
                    </div>
                  </template>
                </b-table>
                <b-row class="vc-pagination" align-h="end" v-if="rows > 0">
                  <div>
                    <f2f-pagination
                      class="mr-4 mb-4"
                      :total-rows="rows"
                      :next="getEnrolledUsers.next"
                      :previous="getEnrolledUsers.previous"
                      :totalPages="getEnrolledUsers.num_pages"
                      :currentPage="getEnrolledUsers.current_page"
                      :pageSize="perPage"
                      @perPageUpdate="onPageCount"
                      @nextPreviousCall="onNextCta"
                      mutationName="SET_ENROLLED_USERS"
                    />
                  </div>
                </b-row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VirtualClassMixin from "./mixin";

import Header from "./Shared/Header.vue";
import AccessDenied from "./Shared/AccessDenied.vue";
import EmptyState from "./Shared/EmptyState.vue";
export default {
  props: {
    classId: {
      type: Number,
      default: -1
    }
  },
  mixins: [VirtualClassMixin],
  components: { Header, AccessDenied, EmptyState },
  data() {
    return {
      perPage: 10,
      searchTerm: "",
      currentSessionDate: "",
      fields: [
        {
          key: "show_details",
          label: ""
        },
        {
          key: "first_name",
          label: "Learner name"
        },
        {
          key: "email",
          label: "Email"
        },
        {
          key: "attendance_status",
          label: "Attendance record",
          thStyle: { width: "25%" }
        },
        {
          key: "attendance_last_updated_at",
          label: "Attendance marked on",
          thStyle: {
            width: "20%",
            "text-align": "center",
            "padding-right": "45px"
          }
        }
      ],
      isBusy: false,
      allSelected: false,
      indeterminate: false,
      selectedIds: [],
      isLoading: false,
      isSearchEnabled: false,
      currentUnit: {}
    };
  },
  watch: {
    selectedIds(newValue) {
      if (newValue.length === 0) {
        this.indeterminate = false;
        this.allSelected = false;
      } else if (newValue.length === this.items.length) {
        this.indeterminate = false;
        this.allSelected = true;
      } else {
        this.indeterminate = true;
        this.allSelected = false;
      }
    },
    getAllLtiUnits() {
      this.updateCurrentUnit();
    }
  },
  computed: {
    ...mapGetters(["getEnrolledUsers"]),
    rows() {
      return this.getEnrolledUsers.count;
    },
    items() {
      if (this.getEnrolledUsers.results) {
        return this.getEnrolledUsers.results;
      } else return [];
    }
  },
  created() {
    this.listEnrolledUsers();
    this.getCurrentBlock();
    // TODO: update using Vue meta if additional meta details are needed.
    document.title = "Attendance Record";
  },
  methods: {
    listEnrolledUsers(showLoader = true, setCurrentPage = false) {
      if (showLoader) {
        this.isLoading = true;
      }
      let currentPage;
      if (setCurrentPage) {
        currentPage = 1;
      } else {
        this.getEnrolledUsers.current_page
          ? (currentPage = this.getEnrolledUsers.current_page)
          : (currentPage = 1);
      }
      const obj = {
        courseId: encodeURIComponent(this.courseId),
        blockId: encodeURIComponent(this.blockId),
        search: this.searchTerm,
        pageSize: this.perPage,
        page: currentPage
      };
      this.$store.dispatch("listEnrolledUsers", obj).then(() => {
        this.isLoading = false;
      });
    },
    toggleAll(checked) {
      this.selectedIds = checked ? this.items.map((i) => i.id) : [];
    },
    onSearchClear() {
      this.searchTerm = "";
      this.isSearchEnabled = false;
      this.listEnrolledUsers();
    },
    onSearch() {
      this.isSearchEnabled = true;
      this.listEnrolledUsers(true, true);
    },
    markUserAttendance(user = null, has_attended, type) {
      let payload;
      if (type === "bulk") {
        let users = this.getEnrolledUsers.results.filter((e) =>
          this.selectedIds.includes(e.id)
        );
        payload = users.map((u) => {
          return {
            user_id: u.id,
            username: u.username,
            attendance_status: has_attended ? "PRESENT" : "ABSENT"
          };
        });
      } else {
        payload = [
          {
            user_id: user.id,
            username: user.username,
            attendance_status: has_attended ? "PRESENT" : "ABSENT"
          }
        ];
      }
      this.markAttendance(payload);
    },
    markAttendance(payload) {
      const params = {
        courseId: encodeURIComponent(this.courseId),
        blockId: encodeURIComponent(this.blockId),
        payload: payload
      };
      this.$store.dispatch("markVirtualAttendance", params).then(() => {
        this.listEnrolledUsers(false);
        this.selectedIds = [];
      });
    },
    getCurrentBlock() {
      if (this.getAllLtiUnits.length === 0) {
        this.listLtiUnits();
      } else {
        this.updateCurrentUnit();
      }
    },
    updateCurrentUnit() {
      this.currentUnit = this.getAllLtiUnits.find(
        (unit) => unit.id === this.blockId
      );
    },
    onPageCount(count) {
      this.perPage = count;
      this.listEnrolledUsers(true, true);
    },
    onNextCta() {
      this.selectedIds = [];
    }
  }
};
</script>

<style lang="scss">
$border-radius-px: 10px;
@mixin active-searchbar {
  border: 1px solid $brand-primary-color;
  border-radius: $border-radius-px;
  box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
  .input-group-text,
  .form-control {
    background: $brand-primary-75;
  }
}

.vc-attendance__list {
  margin-top: 30px;
  .vc-checkbox {
    .custom-control-input {
      &:focus {
        ~ .custom-control-label {
          &::before {
            box-shadow: none;
          }
        }
      }
      &:checked,
      &:indeterminate {
        ~ .custom-control-label {
          &::after {
            border: none;
            background-color: $brand-primary-color;
          }
        }
      }
      &:checked {
        ~ .custom-control-label {
          &::after {
            background: $brand-primary-color 50% / 70% 70% no-repeat;
            background-image: url("~@/assets/images/virtual-classes/tick-white.svg");
          }
        }
      }
    }
    .custom-control-label {
      cursor: pointer;
      &::before,
      &::after {
        border-radius: 4px;
        width: 18px;
        height: 18px;
        border: 2px solid $brand-neutral-300;
      }
    }
  }
  .vc-attendance-list--header {
    justify-content: space-between;
    margin: 30px;
    .vc-attendance__search {
      position: relative;
      width: 450px;
      .input-group {
        border: 1px solid #b0b6c2;
        border-radius: $border-radius-px;
        &.active,
        &:focus {
          @include active-searchbar;
        }
        &:hover {
          background: $brand-primary-100;
          .input-group-text,
          .form-control {
            background: $brand-primary-100;
          }
        }
        .input-group-text {
          cursor: pointer;
          padding: 15px 15px 15px 20px;
        }
        .input-group-append {
          .input-group-text {
            padding: 15px 20px 15px 15px;
          }
        }
        .input-group-text,
        .form-control {
          height: 40px;
          background-color: #fff;
          border: none;
          color: $brand-primary-color;
          border-radius: $border-radius-px;
        }
        .form-control {
          padding: 15px 20px 15px 0px;

          &:focus {
            box-shadow: none;
          }
          &::placeholder {
            color: $brand-neutral-300;
          }
        }
      }
    }
  }
  .vc-attendance__content {
    min-height: 400px;
  }
  .vc-attendance__search-results {
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.25px;
    color: #25282b;
    padding-left: 30px;
  }
  .vcm-list__table--enrollment {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  // dropdown
  .vc-attendance__dropdown {
    .btn {
      &:focus {
        box-shadow: none;
      }
    }
    .btn-secondary {
      border: 1px solid #0043ac !important;
      border-radius: 4px;
      color: #0043ac !important;
      padding: 10px 16px;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      background: transparent;
      &:active {
        background: transparent !important;
      }
    }
    .dropdown-menu {
      padding: 12px 0;
      li {
        padding: 0 4px;
        .dropdown-header,
        .dropdown-item {
          font-family: "Roboto";
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.1px;
        }
        .dropdown-header {
          padding: 0 8px;
          color: $brand-neutral-300;
          margin-bottom: 8px;
        }
        .dropdown-item {
          padding: 8px;
          font-weight: 400;
          color: $brand-neutral-700;
          &:hover {
            background: $brand-primary-100;
            color: $brand-primary-400;
            font-weight: 500;
          }
        }
      }
    }
  }
}
</style>
